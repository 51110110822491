import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
// import { adminLogin } from "../redux/actions/adminauth";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { adminLogin } from "../Redux/Actions/adminauth";

export default function Login() {
  const dispatch = useDispatch();

  const [adminDetails, setadminDetails] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    emailerr: "",
    passworderr: "",
  });

  const handlechange = (e) => {
    setadminDetails((old) => ({ ...old, [e.target.name]: e.target.value }));
    setError((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };

  const handlesubmit = () => {
    validation();
  };

  const validation = async () => {
    let isvalid = true;
    let err = {
      emailerr: "",
      passworderr: "",
    };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!adminDetails.email) {
      err.emailerr = "Please Enter Email";
      isvalid = false;
    } else if (!emailRegex.test(adminDetails.email)) {
      err.emailerr = "Please Enter Valid Email";
      isvalid = false;
    }

    if (!adminDetails.password) {
      err.passworderr = "Please Enter Password";
      isvalid = false;
    }

    if (!isvalid) {
      setError(err);
    }
    if (isvalid) {
      // try {
      dispatch(adminLogin(adminDetails));

      //   if(data?.payload?.status_code === 200){
      //     toast.success(data?.payload?.message)
      //     sessionStorage.setItem("token",data?.payload?.data?.token)

      //     setTimeout(() => {
      //       window.location.href="/Dashboard"
      //     }, 1000);

      //   }else{
      //     toast.error(data?.payload?.message)
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    }
  };
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo-black.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Log in</h2>
                <Form>
                  <div className="form-set">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        value={adminDetails?.email}
                        name="email"
                        type="email"
                        onChange={handlechange}
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">
                        {error?.emailerr}
                      </Form.Label>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        value={adminDetails?.password}
                        name="password"
                        onChange={handlechange}
                        type="password"
                        placeholder="Type Here"
                      />
                      <Form.Label className="text-danger">
                        {error?.passworderr}
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    // to="/Dashboard"
                    variant="primary"
                    // type="submit"
                    onClick={handlesubmit}
                    className="submit"
                  >
                    Log In
                  </div>
                  <Link to="/Forgot-Password" className="forgot">
                    Forgot Password?
                  </Link>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

     
    </div>
  );
}
